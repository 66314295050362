export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'itemCode',
    label: 'field.itemNo',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'name',
    label: 'field.description',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'quantityInHand',
    label: 'field.quantityInHand',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'unit',
    label: 'field.unit',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'receive',
    label: 'field.receive',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'issue',
    label: 'field.issue',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'balance',
    label: 'field.balance',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'cost',
    label: 'field.cost',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'totalCost',
    label: 'field.totalCost',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'remark',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
];
