export default [
  {
    key: 'preparedBy',
    label: 'field.preparedBy',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'userForReport',
    selectionKey: 'id',
    selectionLabel: 'name',
    cols: 12,
  },
  {
    key: 'verifiedBy',
    label: 'field.verifiedBy',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'userForReport',
    selectionKey: 'id',
    selectionLabel: 'name',
    cols: 12,
  },
  {
    key: 'approvedBy',
    label: 'field.approvedBy',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'userForReport',
    selectionKey: 'id',
    selectionLabel: 'name',
    cols: 12,
  },
]
  