<template>
  <b-modal
    id="modal-export"
    cancel-variant="outline-secondary"
    :ok-title="$t('button.save')"
    :cancel-title="$t('button.close')"
    centered
    no-close-on-backdrop
    :title="modalTitle"
    @ok="handleOk"
  >
    <validation-observer ref="exportModal" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="exportModal"
        :form="$refs.exportModal"
        :disabled="invalid"
      >
        <n-input :fields="formInput" v-model="data"> </n-input>
      </n-form-confirmation>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("button.save") }}</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BSpinner, BFormGroup } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";

export default {
  components: {
    BModal,
    BSpinner,
    BFormGroup,
    NFormConfirmation,
    NInput,
    NAsyncSingleSelect,
  },
  data() {
    return {
      loading: false,
      data: {
        preparedBy: null,
        verifiedBy: null,
        approvedBy: null,
      },
      modalTitle: "",
    };
  },
  methods: {
    show() {
      this.$bvModal.show("modal-export");
    },
    hide() {
      this.$bvModal.hide("modal-export");
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.exportModal.validate().then((success) => {
        if (success) {
          this.$emit("export", this.data);
          this.data = {
            preparedBy: null,
            verifiedBy: null,
            approvedBy: null,
          };
          this.hide();
        }
      });
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
