export default [
 
  {
    key: 'fromDate',
    label: 'field.fromDate',
    type: 'date',
  },
  {
    key: 'toDate',
    label: 'field.toDate',
    type: 'date',
  },
  {
    key: 'period',
    label: 'field.period',
    type: 'radio',
    options: [
      { text: 'field.today', value: 'today' },
      { text: 'field.yesterday', value: 'yesterday' },
      { text: 'field.thisWeek', value: 'thisWeek' },
      { text: 'field.lastWeek', value: 'lastWeek' },
      { text: 'field.thisMonth', value: 'thisMonth' },
      { text: 'field.lastMonth', value: 'lastMonth' },
    ],
    md: 12,
    lg: 6,
  },
  {
    key: 'itemId',
    label: 'field.item',
    type: 'nAsynMultiSelection',
    repository: 'item',
    selectionKey: 'id',
    selectionLabel: 'label',
    clearable: true,
    lg: 6
  },
  {
    key: 'type',
    label: 'field.type',
    type: 'radio',
    options: [
      { text: 'general.movementReport', value: 1 },
      { text: 'general.nonMomentReport', value: 2 },
      { text: 'general.monthlyReport', value: 3 },
      { text: 'general.finanaceMonthlyReport', value: 4 },
    ],
    md: 6,
    lg: 6,
  },
];
